@import "../variables.scss";

.loading {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 10;
	background: black;
	.thinking {
		color: #d600ff;
		font-family: "Punk";
		font-size: 30px;
		font-weight: 100;
		text-transform: uppercase;
		position: absolute;
		top: calc(50% + 60px);
		left: 50%;
		transform: translateX(-50%);
		z-index: 20;
		margin: 0;
		animation: pulse-text 1.5s infinite;
	}
	.loading-icon {
		width: 99px;
		height: 100px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		border-radius: 9px;
		z-index: 10;
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			display: block;
			transform: translateX(-50%) translateY(-50%);
			font-family: "Font Awesome 5 Free";
			content: "\f5dc";
			color: white;
			font-weight: 900;
			font-size: 50px;
			animation: pulse-anim 1.5s infinite;
		}
		.letter {
			height: 100%;
			width: auto;
			position: absolute;
			top: 0;
			&.letter-j {
				right: 1px;
			}
			&.letter-c {
				left: 1px;
			}
		}
	}
	&.done {
		.thinking, .loading-icon:before {
			opacity: 0;
			transition: all .5s;
		}
		.letter.letter-j {
			animation: animate-j 1s forwards;
		}
		.letter.letter-c {
			animation: animate-c 1s forwards;
		}
		opacity: 0;
		transition: opacity .5s;
		transition-delay: 1.5s;
	}
	&.hidden {
		display: none;
	}
}

/* Pulse */
@keyframes pulse-anim {
	0%, 100% {  
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
	50% {
		transform: translateX(-50%) translateY(-50%) scale(.8);
	}
}

@keyframes pulse-text {
	0%, 100% {  
		letter-spacing: 2px;
	}
	50% {
		letter-spacing: 4px;
	}
}

@keyframes animate-j {
	15%, 40% { right: -5px; }
	100% { right: 55px; }
}

@keyframes animate-c {
	15%, 40% { left: -5px; }
	100% { left: 55px }
}