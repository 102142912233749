@import "../animations.scss";

section.contact {
	background: black;
	position: relative;
	h1 {
		font-family: "Punk";
		color: rgba(#4abdac, 0.3);
		opacity: 1;
		font-size: 25vw;
		line-height: 50vh;
		text-align: center;
		font-weight: 100;
		@media screen and (max-width: 600px) {
			font-size: 30vw;
			line-height: 30vh;
		}
	}
	.thinking {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		width: 100px;
		height: 100px;
		z-index: 100;
		text-align: center;
		h3 {
			color: #d600ff;
			font-family: "Punk";
			font-size: 30px;
			font-weight: 100;
			text-transform: uppercase;
			text-align: center;
			position: absolute;
			left: 50%;
			bottom: -5px;
			transform: translateX(-50%);
			z-index: 10;
			width: 100%;
			margin: 0;
			animation: pulse-text 1.5s infinite;
		}
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			display: block;
			transform: translateX(-50%) translateY(-50%);
			font-family: "Font Awesome 5 Free";
			content: "\f5dc";
			color: white;
			font-weight: 900;
			font-size: 50px;
			animation: pulse-anim 1.5s infinite;
		}
		&.show {
			transition: all .3s;
			transition-delay: .3s;
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
	}
	.thanks {
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) scale(0);
		opacity: 0;
		color: white;
		text-align: center;
		h2 {
			font-family: "Punk";
			font-size: 100px;
			line-height: 100px;
			font-weight: 100;
			margin: 0;
			letter-spacing: 2px;
			color: #FFF200;
		}
		p {
			font-family: "Orbitron";
			margin: 0;
			color: #00b8ff;
		}
		&.show {
			transition: all .3s;
			transform: translateX(-50%) translateY(-50%) scale(1);
			opacity: 1;
		}
		@media screen and (max-width: 600px) {
			top: 50%;
			h2 {
				font-size: 75px;
				white-space: nowrap;
			}
		}
	}
	.contact-form {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 50vh;
		width: 40%;
		transform: translateX(-50%) translateY(-50%) scale(1);
		transition: all .3s;
		transform-origin: 50% 50%;
		@media screen and (max-width: 600px) {
			width: calc(100vw - 20px);
			top: 40%;
		}
		&.hidden {
			transform: translateX(-50%) translateY(-50%) scale(0);
		}
		.input-wrap {
			height: auto;
			width: auto;
			position: relative;
			margin: 25px 10%;
			&:after {
				width: 110%;
				height: 110%;
				transform: skewX(-10deg);
				content: "";
				background: rgba(#00b8ff, 0.5);
				z-index: -1;
				position: absolute;
				top: -5%;
				left: -5%;
			}
			span {
				z-index: 1;
				position: absolute;
				font-family: "Punk";
				color: black;
				opacity: 0.4;
				font-size: 50px;
				transform: scale(0.8);
				transition: all .3s;
			}
			&.focused span {
				transform: scale(1);
				opacity: 1;
				color: #bd00ff;
			}
			&.name-wrap, &.email-wrap {
				span {
					top: -5px;
					left: -3%;
				}
				&.focused span {
					left: -22%;
				}
			}
			&.message-wrap {
				span {
					transform: rotate(-80deg) translateX(20%) scale(0.8);
					top: 50%;
					left: -14%;
					@media screen and (max-width: 600px) { 
						transform: scale(0.8);
						top:-5px;
						left: -3%;
					}
				}
				&.focused span {
					left: -26%;
					transform: rotate(-80deg) translateX(20%) scale(1);
				}
			}
			@media screen and (max-width: 600px) {
				&:after {
					transform: none;
				}
				&.name-wrap.focused, &.email-wrap.focused, &.message-wrap.focused {
					span {
						opacity: 0;
						left: -22%;
						transform: scale(1);
					}
				}
			}
		}
		input, textarea {
			display: block;
			width: calc(100% - 40px);
			border: none;
			resize: none;
			padding: 0;
			position: relative;
			z-index: 10;
			background: none;
			color: white;
			font-family: "Orbitron";
			@media screen and (max-width: 600px) {
				font-size: 13px;
			}
		}
		input {
			height: 40px;
			padding: 0 20px;
		}
		textarea {
			height: 200px;
			padding: 20px;
			@media screen and (max-width: 600px) {
				height: 130px;
			}
		}
	}
	.submit {
		float: right;
		margin: 10px 70px 0 0;
		transition: all .3s;
		&.disabled {
			opacity: 0.5;
		}
		@media screen and (max-width: 600px) {
			margin: 0px 20px 0 0;
		}
	}
	.social {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		background: #bd00ff;
		span {
			position: relative;
			float: right;
			color: black;
			z-index: 1;
			font-family: "Orbitron";
			font-size: 14px;
			margin: 10px 30px 0 0;
		}
		&:after {
			background: #bd00ff;
			content: "";
			height: 50px;
			width: 100%;
			transform: skewY(1deg);
			position: absolute;
			top: -20px;
			right: 0;
			z-index: 0;
		}
		a {
			display: inline-block;
			position: relative;
			top: -10px;
			z-index: 1;
			width: 40px;
			height: 40px;
			margin: 0 0 0 20px;
			padding: 0;
			cursor: pointer;
			span {
				display: block;
				height: 0;
				width: 0;
				overflow: hidden;
			}
			&:before {
				content: "";
				font-family: "Font Awesome 5 Brands";
				width: 40px;
				font-size: 40px;
				font-weight: 900;
				color: white;
				line-height: 40px;
				text-align: center;
			}
			&.github:before {
				content: "\f113";
			}
			&.linkedin:before {
				content: "\f0e1";
			}
			&.resume:before {
				content: "\f15c";
				font-family: "Font Awesome 5 Free";
			}
			&:hover {
				&:before {
					color: #00ff9f;
				}
			}
		}
	}
}