@import "../variables.scss";
@import '../animations.scss';

section.work {
	background: black;
	position: relative;
	h1 {
		position: absolute;
		z-index: 11;
		font-family: "Punk";
		color: rgba(#d600ff, 0.3);
		text-shadow: 0 0 1px rgba(white, 0.1);
		font-size: 20vw;
		font-weight: 100;
		width: 30vw;
		line-height: 35vw;
		text-align: center;
		transform: rotate(-30deg);
		@media screen and (max-width: 600px) {
			font-size: 35vw;
	    transform: rotate(-10deg);
		}
	}
	.slides {
		position: absolute;
		height: 100vh;
		width: 100vw;
		top: 0;
		left: 0;
	}

	.btn-slash {
		position: absolute;
		bottom: -20px;
	}

	.slide {
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
		pointer-events: none;
		height: 100%;
		width: 100%;
		.slide-overlay {
			position: absolute;
			top: 0;
			right: 25%;
			height: 100%;
			width: 75vw;
			background: url('../images/street.png') no-repeat;
			background-position: top right;
			background-size: cover;
			z-index: 10;
			@media screen and (max-width: 600px) {
				right: auto;
				width: 150vw;
				left: 0;
			}
		}
	}

	.slide--current {
		position: relative;
		opacity: 1;
		pointer-events: auto;
	}

	.slide__img {
		width: 60vw;
		height: 100vh;
		position: absolute;
		top: 0;
		right: 0;
	}

	.slide__text {
		position: absolute;
		top: 40%;
		left: 20vw;
		z-index: 20;
		width: calc(35vw - 100px);
		min-height: 25vh;
		padding: 20px 50px 50px;
		background: rgba(black, 0.6);
		transform: skewX(-20deg);
		* {
			transform: skewX(20deg);
		}
		@media screen and (max-width: 600px) {
			transform: none;
			width: calc(90% - 20px);
	    min-height: 25vh;
	    padding: 10px 10px 50px;
	    top: 20%;
    	left: 5%;
    	z-index: 10;
			*{
				transform: none;
			}
		}
	}

	.slide:first-child .slide__text {
		color: #FFF200;
	}

	.slide:nth-child(2) .slide__text {
		color: #00b8ff;
	}

	.slide:nth-child(3) .slide__text {
		color: #00ff9f;
	}

	.slide--current .slide__title {
		animation: glitch-anim-title 0.25s linear alternate 1 backwards;
	}

	.slide__title {
		font-weight: 400;
		font-size: 6rem;
		margin: 0 0 0.25rem;
		font-family: 'Punk', sans-serif;
		font-weight: 100;
		text-transform: uppercase;
		cursor: default;
		position: absolute;
		top: -65px;
		right: -20px;
		text-shadow: 0 0 10px rgba(black, 0.8);
		@media screen and (max-width: 600px) {
			font-size: 3.5rem;
			top: -40px;
    	right: -10px;
		}
	}

	.slide__description {
		font-size: 1rem;
		letter-spacing: 1px;
		font-weight: 400;
		text-transform: none;
		max-width: 100%;
		cursor: default;
		color: #fff;
		font-family: "Orbitron";
		line-height: 24px;
		@media screen and (max-width: 600px) {
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 50px;
		}
	}

	.slide__used {
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			line-height: 30px;
			width: 50%;
			vertical-align: top;
			transform: skewX(0);
			font-family: "Orbitron";
			font-size: 18px;
			font-weight: 100;
			color: white;
			font-style: italic;
			&:before {
				content: "-";
				font-family: "Punk";
				font-size: 80px;
				line-height:30px;
				display: inline-block;
				vertical-align: top;
				margin-right: 10px;
				color: #00ff9f;
			}
			@media screen and (max-width: 600px) {
				font-size: 14px;
				&:before {
					font-size: 70px;
				}
				&.Service {
					display: none;
				}
			}
		}
	}

	.slide__description a {
		color: #aaa;
		pointer-events: auto;
	}

	.slide__description a:hover {
		color: #fff;
	}

	.slide-nav__button {
		display: block;
		background: none;
		position: absolute;
		z-index: 20;
		top: 50%;
		border: 0;
		margin: 0 1rem 0 0;
		padding: 0;
		width: 50px;
		height: 50px;
		cursor: pointer;
		&:before {
			width: 100%;
			height: 100%;
			line-height: 50px;
			font-size: 50px;
			text-align: center;
			font-family: "Font Awesome 5 Free";
			color: black;
			font-weight: 900;
			-moz-osx-font-smoothing: grayscale;
		  -webkit-font-smoothing: antialiased;
		  text-rendering: optimizeLegibility;
		  backface-visibility: hidden;
		  text-shadow: 0 0 10px #00ff9f;
		}
		&.prev {
			left: 11vw;
			&:before {
				content: "\f137"
			}
		}
		&.next {
			right: 35vw;
			&:before {
				content: "\f138"
			}
		}
		&:hover {
			&:before {
				color: #00ff9f;
				text-shadow: 0 0 10px black;
			}
		}
		@media screen and (max-width: 600px) {
			z-index: 50px;
			margin: 0;
			top: 53%;
			&.prev {
				left: 5px;
			}
			&.next {
				right: 5px;
			}
		}
	}

	.slide-nav__button:focus {
		outline: none;
	}

	.slide-nav__text {
		font-size: 4vw;
		margin: 0.75rem 0;
		font-family: 'Playfair Display', sans-serif;
		font-weight: 900;
		text-transform: lowercase;
		letter-spacing: 0;
		color: inherit;
	}

	.slide-nav__text:hover {
		color: red;
	}

	.slide-nav__text--current:hover,
	.slide-nav__text:focus {
		color: green;
	}

	/* Glitch styles */
	.glitch {
		overflow: hidden;
	}

	.glitch,
	.glitch__img {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	.glitch__img {
		position: absolute;
		top: calc(-1 * 5px);
		left: calc(-1 * 10px);
		width: calc(100% + 10px * 2);
		height: calc(100% + 5px * 2);
		background-color: transparent;
		transform: translate3d(0,0,0);
		background-blend-mode: none;
	}

	.glitch__img:nth-child(n+2) {
		opacity: 0;
	}

	.glitch--animate .glitch__img:nth-child(n+2) {
		animation-duration: 2s;
		animation-delay: 0s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
	}

	.glitch--animate .glitch__img:nth-child(2) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: work-glitch-anim-1;
	}

	.glitch--animate .glitch__img:nth-child(3) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: work-glitch-anim-2;
	}

	.glitch--animate .glitch__img:nth-child(4) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: work-glitch-anim-3;
	}

	.glitch--animate .glitch__img:nth-child(5) {
		background-color: #af4949;
		background-blend-mode: overlay;
		animation-name: glitch-anim-flash;
	}

	@media screen and (max-width: 600px) {
		.slide-overlay {
			right: auto;
			left: 0;
		}
		.slide__img {
			display: none;
		}
	}
}