@import "../variables.scss";
@import '../animations.scss';

section.intro {
	overflow: hidden;
	.intro-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
		color: white;
		font-family: "Orbitron";
		font-size: 30px;
		white-space: nowrap;
		span {
			font-size: 30px;
			text-align: right;
			display: block;
			font-family: "Punk";
			letter-spacing: 1px;
			font-weight: 100;
			color: #FFF200;
		}
	}
	.down {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		width: 50px;
		height: 50px;
		&:before, &:after {
			font-family: "Font Awesome 5 Free";
			content: "\f078";
			font-weight: 900;
			display: block;
			text-align: center;
			font-size: 30px;
			color: #00b8ff;
			animation: coax-anim 1.5s infinite;
			line-height:10px;
		}
	}
	/* Glitch styles */
	.glitch-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.glitch-img {
		position: absolute;
		top: calc(-1 * 5px);
		left: calc(-1 * 10px);
		width: calc(100% + 10px * 2);
		height: calc(100% + 5px * 2);
		background: url("../images/city.jpg") no-repeat 50% 0;
		background-color: transparent;
		background-size: cover;
		transform: translate3d(0,0,0);
		background-blend-mode: none;
	}

	.glitch-img:nth-child(n+2) {
		animation-duration: 4s;
		animation-delay: 2s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}

	.glitch-img:nth-child(2) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: glitch-anim-1;
	}

	.glitch-img:nth-child(3) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: glitch-anim-2;
	}

	.glitch-img:nth-child(4) {
		background-color: transparent;
		background-blend-mode: none;
		animation-name: glitch-anim-3;
	}

	.glitch-img:nth-child(5) {
		background-color: #276560;
		background-blend-mode: overlay;
		animation-name: glitch-anim-flash;
	}

	@media screen and (max-width: 768px) {
		.glitch-img:nth-child(2), .glitch-img:nth-child(3), .glitch-img:nth-child(4),.glitch-img:nth-child(5) {
			display: none;
		}
	}
	@media screen and (max-width: 600px) {
		.intro-content h1 {
			font-size: 32px;
		}
	}
}