section.skills {
	background: black;
	position: relative;
	h1 {
		position: relative;
		font-family: "Punk";
		font-size: 60vh;
		line-height: 100vh;
		color: rgba(#00ff9f, 0.1);
		font-weight: 100;
		text-align: center;
		z-index: 1;
	}
}

.skill-intro{
	position: absolute;
  top: 30%;
  left: 50%;
  width: 30%;
  min-width: 300px;
  min-height: 300px;
  font-size: 18px;
  line-height: 24px;
  transform: translateX(-50%) translateY(-50%);
	color: white;
	font-family: "Orbitron";
	z-index: 5;
	@media screen and (max-width: 600px) {
		font-size: 14px;
		line-height: 18px;
	}
}

#balls {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	canvas {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
}