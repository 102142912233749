.menu {
	position: fixed;
	top: -50px;
	left: 0;
	width: 100%;
	height: 50px;
	background: rgba(black, 0.2);
	z-index: 50;
	> img {
		height: 30px;
		width: auto;
		margin: 10px 0 0 20px;
		display: inline-block;
	}
	&.show {
		top: 0;
		transition: all .3s;
	}
	ul {
		margin: 0 30px 0 0;
		padding: 0;
		display: inline-block;
		height: 50px;
		float: right;
		li {
			display: inline-block;
			vertical-align: top;
			height: 50px;
			line-height: 50px;
			margin: 0 15px;
			font-family: "Punk";
			font-weight: 100;
			color: white;
			font-size: 30px;
			letter-spacing: 1px;
			cursor: pointer;
			&:hover {
				color: #FFF200;
			}
		}
	}
	@media screen and (max-width: 600px) {
		ul {
			margin-right: 10px;
			li {
				margin: 0 10px;
				font-size: 24px;
			}
		}
	}
}